<template>
  <div class="in-the-review">
    <el-card class="title-icon">
      <div>
        <img src="@/assets/images/kingdee-icon.png" alt="">
      </div>
    </el-card>
    <div class="in-the-review_content">
      <div class="card">
        <div class="text-center">
          <img
            src="@/assets/images/kingdee-process-one.png"
            style="width:450px"
            alt=""
          >
        </div>
        <div class="text-center mt-82">
          <img
            :src="status === '2' ? kingdeeAuditing : kingdeeAuditFailure "
            class="img-w-h-75"
            alt=""
          >
          <div class="fw-500 fs-18 color-303133 mt-12">
            {{ status === '2' ? '审核中…' : '审核失败' }}
          </div>
          <div v-if="status === '2'" class="color-F56C6C fs-14 mt-5">
            已提交申请，审核需一个工作日，审核通过后即可使用
          </div>
          <div v-else class="color-F56C6C fs-14 mt-5 w-300">
            审核失败原因：{{ formData.applyFailContent }}
          </div>
          <div v-if="status !== '2'" class="color-F56C6C fs-14 mt-5">
            请修改绑定客户与账套页面信息，重新提交申请
          </div>
        </div>
      </div>

      <div class="bootom-button" v-if="status === '4'">
        <el-button
          type="primary"
          size="small"
          @click="goBackUpdata"
        >
          返回修改
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import kingdeeAuditing from '@/assets/images/kingdee-auditing.png'
import kingdeeAuditFailure from '@/assets/images/kingdee-audit-failure.png'
export default {
  name: 'InTheReview',
  data () {
    return {
      kingdeeAuditing: kingdeeAuditing,
      kingdeeAuditFailure: kingdeeAuditFailure,
      formData: {},
      status: 1 // 状态2：表示审核中 状态4：表示审核失败 状态  3表示审核成功
    }
  },
  methods: {
    /**
       * 返回修改
       */
    goBackUpdata () {
      this.$router.push(`/kingdee-audit?accountId=${this.formData.accountId}&flag=1&uuId=${this.formData.uuId}`)
    }
  },
  created () {
    this.status = this.$route.query.status
    this.formData.applyFailContent = decodeURIComponent(this.$route.query.applyFailContent)
    this.formData.accountId = this.$route.query.accountId
    this.formData.uuId = this.$route.query.uuId
  }
}
</script>

<style scoped lang="scss">
  .in-the-review{
    position:absolute;
    bottom:0;
    left: 0;
    background-color:#EBEDF0;
    height:100%;
    width:100%;
    .title-icon{
      height:52px;
      line-height: 52px;
      &::v-deep {
        .el-card__body {
          padding: 0px 24px 5px 24px;
        }
      }
      img{
        width: 120px;
      }
    }
    &_content{
      padding:24px;
      margin:24px;
      height:calc(100% - 160px);
      background-color:#fff;
      .w-300{
        width:280px;
        word-break:break-all;
        margin: 5px auto;
      }
      .color-F56C6C{
        color: #F56C6C;
      }
      .color-303133{
        color: #303133;
      }
      .img-w-h-75{
        width:75px;
        height:75px;
      }
      .mt-82{
        margin-top:82px;
      }
    }
    .bootom-button{
      position: fixed;
      left: 0;
      bottom:0;
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: rgba(232, 244, 255, 0.9);
    }
  }
</style>

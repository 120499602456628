import { render, staticRenderFns } from "./in-the-review.vue?vue&type=template&id=cdf78448&scoped=true"
import script from "./in-the-review.vue?vue&type=script&lang=js"
export * from "./in-the-review.vue?vue&type=script&lang=js"
import style0 from "./in-the-review.vue?vue&type=style&index=0&id=cdf78448&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdf78448",
  null
  
)

export default component.exports